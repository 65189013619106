import Box from '@mui/material/Box'
import { useResize, useI18n, formatPrice } from '@popety_io/popety-io-lib'
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts'
import { useTheme } from '@mui/material'
import { CustomizedPriceLabel } from '../../pages/Estimate/components/Listing/ListingStats/CustomizedXQuartertoYearLabel'
import CustomizedXQuartertoYearLabel from './CustomizedXQuartertoYearLabel'

type GraphStatsProps = {
  dataBuyAllPrice: number
  dataBuyAllPriceEvolution: number
  dataBuyAllYearlyHistory: any
  title?: string
}

const GraphStats = ({
  dataBuyAllPrice,
  dataBuyAllPriceEvolution,
  dataBuyAllYearlyHistory,
  title = '',
}: GraphStatsProps) => {
  const { t } = useI18n()
  const { ref, width = 530 } = useResize()
  const pricePerSquareMeter = t('land.priceM2')
  const theme = useTheme()

  return (
    <Box ref={ref} ml="-16px" mt={3} width="100%" height="100%">
      <Box
        sx={{
          backgroundColor: '#fff',
          width,
          padding: '0px 0px 0px 0px',
          fontWeight: 400,
          fontFamily: 'Roboto',
          color: 'rgb(4, 26, 37)',
          marginRight: 10,
          marginLeft: 3,
        }}
      >
        <span style={{ fontSize: '1.1rem' }}>{title}</span>
        <div style={{ fontSize: '1.2rem', marginTop: 10, marginBottom: 15 }}>
          <span
            style={{
              fontSize: '1.5rem',
              color: theme?.palette?.primary?.main || '#00ade6',
            }}
          >
            {formatPrice(dataBuyAllPrice)}
          </span>
          <span
            style={{
              fontSize: '0.9rem',
              paddingLeft: 10,
              color: 'rgba(127, 188, 83, 0.7)',
            }}
          >
            {' '}
            +{dataBuyAllPriceEvolution?.toFixed(2)}%
          </span>
          <span
            style={{
              fontSize: '0.7rem',
              paddingLeft: 5,
              color: 'rgb(207, 207, 207)',
            }}
          >
            / {t('common.12 dernier mois')}
          </span>
        </div>
        <AreaChart
          width={width}
          height={140}
          data={dataBuyAllYearlyHistory}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme?.palette?.primary?.main || '#00ade6'}
                stopOpacity={0.15}
              />
              <stop
                offset="55%"
                stopColor={theme?.palette?.primary?.main || '#00ade6'}
                stopOpacity={0.08}
              />
              <stop
                offset="90%"
                stopColor={theme?.palette?.primary?.main || '#00ade6'}
                stopOpacity={0.03}
              />
              <stop
                offset="100%"
                stopColor={theme?.palette?.primary?.main || '#00ade6'}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey={pricePerSquareMeter}
            stroke={theme?.palette?.primary?.main || '#00ade6'}
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <XAxis
            style={{ fontSize: '0.7rem' }}
            dataKey="date"
            interval="preserveEnd"
            tickLine={false}
            type="category"
            domain={['dataMin', 'dataMax']}
            tick={<CustomizedXQuartertoYearLabel />}
            tickFormatter={(value) => {
              return value.replace(/\s/g, '')
            }}
          />
          <YAxis
            domain={['dataMin', 'dataMax']}
            axisLine={false}
            tickLine={false}
            tick={<CustomizedPriceLabel />}
          />
          <Tooltip />
        </AreaChart>
      </Box>
    </Box>
  )
}

export default GraphStats
