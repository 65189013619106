import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { BaseLayout, useMediaQuery, useRouter } from '@popety_io/popety-io-lib'

import {
  getEstimateSummary,
  estimateLoadingSelector,
  estimateErrorSelector,
} from '../pages/Estimate/redux'
import { useAppSelector } from '../redux/store'
import { handleSideBar } from '../redux/appSlice'
import AppMenu from './AppMenu'
import HeaderActions from './HeaderActions'
import HeaderLeft from './HeaderLeft'
import HeaderRight from './HeaderRight'
import SideBox from './SideBox'

const Layout = ({ children }: any) => {
  const loading = useSelector(estimateLoadingSelector)
  const error = useSelector(estimateErrorSelector)
  const { query } = useRouter()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const openSideBar: any = useAppSelector((state: any) => state.app.openSideBar)
  const dispatch = useDispatch()

  const { hash } = query

  const onSideMenuOpen = (open: boolean) => {
    dispatch(handleSideBar(open))
  }

  useEffect(() => {
    if (hash) dispatch(getEstimateSummary({ hash }) as any)
  }, [hash])

  useEffect(() => {
    if (isDesktop) {
      dispatch(handleSideBar(true))
    }
  }, [dispatch, isDesktop, window.location.pathname])

  const isShowCloseSidePaneIcon = [].some((p) =>
    window.location.pathname.includes(p),
  )

  if (!loading && error) {
    window.location.href = '/link-expired'
  }

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Ensure the Box takes the full height of the viewport
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <BaseLayout
          SideBox={<SideBox />}
          HeaderActions={<HeaderActions />}
          HeaderLeft={<HeaderLeft />}
          HeaderRight={<HeaderRight />}
          Menu={AppMenu}
          onSideMenuOpen={onSideMenuOpen}
          openSidebar={openSideBar}
          hideSidebarCloseIcon={!isShowCloseSidePaneIcon}
        >
          {children}
        </BaseLayout>
      )}
    </>
  )
}

export default Layout
