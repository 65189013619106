/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getIsochrone as getIsochroneLayer } from './poisService'

const initialState: any = {
  display: false,
  transportType: 'walking',
  isochrone: {},
}

export const getIsochrone = createAsyncThunk(
  'pois/getIsochrone',
  getIsochroneLayer,
)

const pois = createSlice({
  name: 'pois',
  initialState,
  reducers: {
    updateLivabilityOptions: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIsochrone.fulfilled, (state, action) => {
      const { payload } = action

      if (!payload) {
        state.isochrone = {}
      } else {
        state.isochrone = {
          ...payload,
          features: payload.features?.map((f: any, index: number) => {
            if (index === 0) return { ...f, category: 'drive' }
            if (index === 1) return { ...f, category: 'bike' }
            if (index === 2) return { ...f, category: 'walking' }

            return 0
          }),
        }
      }
    })
  },
})

export const poisReducer = {
  pois: pois.reducer,
}

export const { updateLivabilityOptions } = pois.actions
