import { combineReducers } from '@reduxjs/toolkit'
import {
  estimateSummaryReducer,
  basemapReducer,
  poisReducer,
  sunlightReducer,
} from '../pages/Estimate/redux'
import { appReducer } from './appSlice'

const rootReducer = combineReducers({
  ...appReducer,
  ...estimateSummaryReducer,
  ...basemapReducer,
  ...poisReducer,
  ...sunlightReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  return rootReducer(state, action)
}
