import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetLandOpts = {
  hash: string
}

const getEstimateSummary = ({ hash }: GetLandOpts) => {
  return client.post(`/public/estimate`, { hash })
}

const getPublicEditedEstimate = (id: any) => {
  return client.get(`/estimate/edited/public/${id}`)
}

const getTransactionsTileDetails = (tile: string[], q = '') => {
  return client.get(
    `/public/transactions/tiles/details?tile=${tile}&query=${q}`,
  )
}

const getFaosTileDetails = (tile: string[], q = '') => {
  return client.get(`/public/faos/tiles/details?tile=${tile}&query=${q}`)
}

const getListingTileDetails = (tile: string[], q = '') => {
  return client.get(`/public/listing/tiles/details?tile=${tile}&query=${q}`)
}

const summaryService = {
  getEstimateSummary,
  getPublicEditedEstimate,
  getTransactionsTileDetails,
  getFaosTileDetails,
  getListingTileDetails,
}

export default summaryService
