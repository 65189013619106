import Divider from '@mui/material/Divider'

import { useI18n, format } from '@popety_io/popety-io-lib'
import DescriptionHeader from './DescriptionHeader'
import { ContainerPaper, StyledDiv, StyledValue } from './Description.style'

const Description = ({ data }: any) => {
  const { t } = useI18n()

  return (
    <ContainerPaper>
      {data && <DescriptionHeader data={data} />}
      <Divider />
      {data?.previous_transaction_date && (
        <StyledDiv>
          <span>{t('land.history.previousTransaction')}</span>
          <StyledValue>{data?.previous_transaction_date}</StyledValue>
        </StyledDiv>
      )}
      {data?.listing_timestamp && (
        <StyledDiv>
          <span>{t('land.history.listingDate')}</span>
          <StyledValue>{format(data?.listing_timestamp, 'P')}</StyledValue>
        </StyledDiv>
      )}
      {data?.work_type && (
        <StyledDiv>
          <span>{t('land.history.workType')}</span>
          <StyledValue>{data?.work_type}</StyledValue>
        </StyledDiv>
      )}
      {(data?.work_description || data?.description) && (
        <StyledDiv>
          <span>{t('land.history.description')}</span>
          <StyledValue
            dangerouslySetInnerHTML={{
              __html: data?.work_description || data?.description,
            }}
          />
        </StyledDiv>
      )}
      {data?.dispensation && (
        <StyledDiv>
          <span>{t('land.history.dispensation')}</span>
          <StyledValue>{data?.dispensation}</StyledValue>
        </StyledDiv>
      )}
      <Divider />
      {data?.jurisdiction_code && (
        <StyledDiv>
          <span>{t('land.history.authority')}</span>
          <StyledValue>
            {`(${data?.jurisdiction_code}) ${data?.jurisdiction_name}`}
          </StyledValue>
        </StyledDiv>
      )}
      {data?.owner && (
        <StyledDiv>
          <span>{t('land.history.owners')}</span>
          <StyledValue>{data?.owner}</StyledValue>
        </StyledDiv>
      )}
      {data?.promisor && (
        <StyledDiv>
          <span>{t('land.history.promiser')}</span>
          <StyledValue>{data?.promisor}</StyledValue>
        </StyledDiv>
      )}
      {data?.architect_name && (
        <StyledDiv>
          <span>{t('land.history.architect')}</span>
          <StyledValue>
            {data?.architect_name && data?.architect_name}
            {data?.architect_street && `, ${data?.architect_street}`}
            {data?.architect_city && `, ${data?.architect_city}`}
          </StyledValue>
        </StyledDiv>
      )}
      {data?.details && (
        <StyledDiv>
          <span>{t('land.history.details')}</span>
          <StyledValue>{data?.details}</StyledValue>
        </StyledDiv>
      )}
    </ContainerPaper>
  )
}

export default Description
