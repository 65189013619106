import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const EstimateHeader = lazy(
  () => import('../pages/Estimate/components/EstimateHeader'),
)

const LayoutHeaderLeft = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<EstimateHeader />} />
        <Route path="/link-expired" element={null} />
        <Route path="/:lang" element={<EstimateHeader />} />
        <Route path="*" element={null} />
      </Routes>
    </Suspense>
  )
}

export default memo(LayoutHeaderLeft)
