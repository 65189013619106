import { API_BASE_URL } from '../services'

/**
 * transformTileRequest
 */
const transformTileRequest = (url: string, resourceType?: string) => {
  try {
    const accessToken = localStorage.getItem('jwt')
    const headers = { Authorization: `JWT ${accessToken}` }

    if (resourceType && url.startsWith(API_BASE_URL as string)) {
      return { url, headers }
    }
  } catch (error) {
    console.error(error)
  }
}

export { transformTileRequest }
