import { MAPBOX_TOKEN } from '@popety_io/popety-io-lib'

export type GetIsoChroneOpts = {
  type: string
  minutes?: number | number[]
  polygons?: boolean
  lon: number
  lat: number
}

const getIsochrone = async (opts: GetIsoChroneOpts) => {
  const { type, polygons = true, lon, lat, minutes } = opts

  const minutesArray = minutes && (Array.isArray(minutes) ? minutes : [minutes])

  const contoursMinutes =
    minutesArray || (type === 'walking' ? [2, 5, 15] : [5, 15, 30])

  try {
    const contours = encodeURIComponent(contoursMinutes.join(','))
    const pol = polygons ? 'true' : 'false'

    const res = await fetch(
      `https://api.mapbox.com/isochrone/v1/mapbox/${type}/${lon}%2C${lat}?contours_minutes=${contours}&polygons=${pol}&generalize=0&access_token=${MAPBOX_TOKEN}`,
      { method: 'GET' },
    )

    return await res.json()
  } catch (e) {
    throw new Error(e as any)
  }
}

export { getIsochrone }
