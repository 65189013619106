import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const EstimateMenu = lazy(
  () => import('../pages/Estimate/components/EstimateMenu'),
)

const SideBox = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<EstimateMenu />} />
        <Route path="/:lang" element={<EstimateMenu />} />
        <Route path="*" element={null} />
      </Routes>
    </Suspense>
  )
}

export default memo(SideBox)
