/* eslint-disable no-nested-ternary */
import { useEffect } from 'react'
import {
  LayersIcon,
  MapLegend,
  useI18n,
  useRouter,
  Select,
} from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconBtton from '@mui/material/IconButton'

type IconWIthLegendsProps = {
  options: any
  value: string
  keyValue: string
  displayValue: string
  children: any
  id: string
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void
  title?: string
}

const IconWIthLegends = ({
  options,
  value,
  keyValue,
  displayValue,
  children,
  id,
  onChange,
  title,
  ...other
}: IconWIthLegendsProps) => {
  const { t } = useI18n()

  const { updateQuery, query } = useRouter()

  const display = query[displayValue] === 'true'

  useEffect(() => {
    return () => {
      updateQuery({ [keyValue]: undefined })
    }
  }, [])

  const handleDisplayLegend = (display: boolean) => {
    updateQuery({ [displayValue]: display })
  }

  useEffect(() => {
    updateQuery({ mapLg: id })
  }, [])

  return (
    <MapLegend
      title={title || t('common.layers')}
      id={id}
      top="16px"
      icon={LayersIcon}
      zIndex={1000}
      style={{
        width:
          query.mapLg === 'sun-hours'
            ? query.fs === '1'
              ? '33%'
              : '90%'
            : 'auto',
        overflowX: 'hidden',
      }}
      right="16px"
      left="auto"
      padding="4px"
      {...other}
    >
      <Box mb="4px">
        <Select fullWidth options={options} value={value} onChange={onChange} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: '#969696',
              fontSize: '0.9rem',
              fontWeight: 500,
              lineHeight: 1.66,
              textTransform: 'uppercase',
            }}
          >
            {t('land.livabilityScore.Legend')}
          </Typography>
        </Box>
        <Box>
          <IconBtton onClick={() => handleDisplayLegend(!display)}>
            {!display ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <VisibilityOffIcon fontSize="small" />
            )}
          </IconBtton>
        </Box>
      </Box>
      {display && children}
    </MapLegend>
  )
}

export default IconWIthLegends
