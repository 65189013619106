import { ProgressIndicator } from '@popety_io/popety-io-lib'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import RouteGuard from './RouteGuard'
import { navigations } from './navigations'

const AppRoutes = () => {
  return (
    <Suspense fallback={<ProgressIndicator circular anchor="window" />}>
      <Routes>
        {navigations.map((nav) => (
          <Route key={nav.path} element={<RouteGuard />}>
            <Route path={nav.path} element={nav.page} />
          </Route>
        ))}
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
