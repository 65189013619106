import pkg from '../../package.json'

// TODO: check that all values are used

/**
 * Indicates whether NODE_ENV is production
 * @namespace Config
 */
export const isProd = process.env.NODE_ENV === 'production'

/**
 * Indicates whether NODE_ENV is development
 * @namespace Config
 */
export const isDev = process.env.NODE_ENV === 'development'

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN as string

export const GOOGLE_MAP_TOKEN =
  (process.env.GOOGLE_MAP_API_KEY as string) ||
  'AIzaSyDYwQ1GN907ZCPj0Vd75r39GQm2qpvyogs'

export const DEFAULT_OFFICE_IMG = '/assets/img/office-icon.png'

export const DEFAULT_MAPSTYLE =
  'mapbox://styles/popety/cl3jyrrml000614n6aelcet8c'

export const SATELLITE_MAPSTYLE =
  'mapbox://styles/popety/cl449l1ah000414umwqgcr0pd'

export const STYLE_PREFIX = 'mapbox://styles/'

export const APP_VERSION = process.env.REACT_APP_VERSION || pkg.version

export const LIB_VERSION = pkg.dependencies[
  '@popety_io/popety-io-lib'
]?.replace('^', '')

export const livabilityDistance = 2400

/**
 * SENTRY SETUP
 */
export const SENTRY_DNS =
  'https://86b9b57afc3e40b4b55a8ea018dcbb13@o926084.ingest.sentry.io/4504769294041088'

/**
 * App pages. key is the unique key that identify each page. It is used to load i18n by page.
 * So add it must be unique and must match the its i18n folder like i18n/language/page where
 * page is the unique key of the page. Ex: i18n/fr/home
 * @namespace Config
 */
export const PAGES = [
  {
    pathname: '/',
    key: 'land-detail,listingHistory,faoHistory,transactionHistory,driver,estimate,dashboard,search,listing',
  },
] as const

export const LOCATION_TYPE = {
  ADDRESS: 'address',
  REGION: 'region',
  CITY: 'city',
  DISTRICT: 'district',
  LAND: 'land',
  NPA: 'npa',
  MUNICIPALITY: 'municipality',
} as const

export type LocationType = (typeof LOCATION_TYPE)[keyof typeof LOCATION_TYPE]

export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const EMAIL_REGEX = /^\S+@\S+\.\S+$/

export const LANG_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'Allemand' },
]
