import { createSelector } from '@reduxjs/toolkit'

const landSunlightsSelector = (state) => state.sunlight?.sunlights

const landPotentialSolarDataSelector = (state) =>
  state.sunlight?.landSunLightData

const buildingPotentialSolarDataSelector = (state) =>
  state.sunlight?.buildingSunLightData

const estimatePotentialSolarDataSelector = (state) =>
  state.sunlight?.estimateSunLightData

const potentialSolarIsLoadingSelector = (state) => state.sunlight?.loading

const sunDateSelector = (state) => state.sunlight?.date

const sunlightTimesSelector = (state) => state.sunlight?.sunlightTimes

const sunlightWinterTimesSelector = (state) =>
  state.sunlight?.sunlightWinterTimes

const sunlightSummerTimesSelector = (state) =>
  state.sunlight?.sunlightSummerTimes

const getTimeDiff = (time) =>
  !time ? 0 : (time.sunset - time.sunrise) / 1000 / 3600

const sunlightHoursSelector = createSelector(sunlightTimesSelector, getTimeDiff)

const sunlightWinterHoursSelector = createSelector(
  sunlightWinterTimesSelector,
  getTimeDiff,
)

const sunlightSummerHoursSelector = createSelector(
  sunlightSummerTimesSelector,
  getTimeDiff,
)

const showSunExposureSelector = (state) => state.sunlight?.showSunExposure

const customSunlightBuildingSelector = (state) =>
  state.sunlight?.customSunlightBuilding

const customSunlightBuildingsPolygonsSelector = (state) =>
  state.sunlight?.customSunlightBuildingsPolygons

const monthlyAverageExposureSelector = (state) =>
  state.sunlight?.monthlyAverageExposure

export {
  landSunlightsSelector,
  sunDateSelector,
  sunlightHoursSelector,
  showSunExposureSelector,
  sunlightTimesSelector,
  sunlightSummerTimesSelector,
  sunlightWinterTimesSelector,
  sunlightWinterHoursSelector,
  sunlightSummerHoursSelector,
  getTimeDiff,
  landPotentialSolarDataSelector,
  buildingPotentialSolarDataSelector,
  potentialSolarIsLoadingSelector,
  customSunlightBuildingSelector,
  customSunlightBuildingsPolygonsSelector,
  estimatePotentialSolarDataSelector,
  monthlyAverageExposureSelector,
}
