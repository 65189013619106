import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import MailIcon from '@mui/icons-material/Mail'
import { Box, Grid } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { userSelector } from '../../pages/Estimate/redux'

interface SupportModalProps {
  open: boolean
  handleModal: (state: boolean) => void
}

const SupportModal = ({ open, handleModal }: SupportModalProps) => {
  const { t } = useI18n()

  const user = useSelector(userSelector)

  const handleToggle = (v: boolean) => {
    handleModal(v)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleToggle(false)
        }}
        title={t('common.support')}
        showCloseIcon
        cancelText={t('common.close')}
        maxWidth="sm"
        fullWidth
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box>
              {!false && (
                <>
                  {/* <Typography variant="h6">
                    {t('common.accountManager')}
                  </Typography> */}
                  <Stack
                    direction="row"
                    spacing={2}
                    component="p"
                    color="inherit"
                    mb={2}
                    mt={2}
                  >
                    <ContactPhoneIcon />
                    <Typography>
                      {user?.first_name} {user?.last_name}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    href={`tel:${user?.phone}`}
                    component="a"
                    color="inherit"
                    mb={2}
                    mt={2}
                  >
                    <ContactPhoneIcon />
                    <Typography>{user?.phone}</Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    href={`mailto:${user?.email}`}
                    component="a"
                    color="inherit"
                    mb={2}
                  >
                    <MailIcon />
                    <Typography>{user?.email}</Typography>
                  </Stack>
                </>
              )}
              {false &&
                [1, 2, 3, 4, 5].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '1rem',
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.5rem', borderRadius: 2, width: '6%' }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '1.5rem', borderRadius: 2, width: '45%' }}
                    />
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default SupportModal
