import { memo, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const HeaderRight = () => (
  <Suspense>
    <Routes>
      <Route path="*" element={null} />
    </Routes>
  </Suspense>
)

export default memo(HeaderRight)
