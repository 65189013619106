import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

import { isProd } from '../config'
import rootReducer, { RootState } from './reducers'

const unauthorizedMiddleware = () => (next: any) => (action: any) => {
  const result = next(action)

  // Check if the action resulted in a 401 error
  if (result?.payload?.errors?.[0]?.status === 401) {
    // Dispatch the logoutUser action to clear user data
    sessionStorage.clear()
  }

  return result
}

const store = configureStore({
  reducer: rootReducer,
  devTools: !isProd,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(unauthorizedMiddleware),
})

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
