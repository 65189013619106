import { useSelector } from 'react-redux'

import { BrowserRouter } from 'react-router-dom'
import { I18nProvider, ThemeProvider } from '@popety_io/popety-io-lib'

import Routes from './navigation/Routes'
import Layout from './Layout'

import '@popety_io/popety-io-lib/dist/styles/GlobalStyle.css'
import { PAGES } from './config'
import {
  companySelector,
  officeSelector,
  themeSelector,
} from './pages/Estimate/redux'

const AppThemeProvider = () => {
  const companyInfo = useSelector(companySelector)
  const officeInfo = useSelector(officeSelector)
  const themeProvided = useSelector(themeSelector)

  const themeColors = {
    primary:
      themeProvided?.primary ||
      officeInfo?.colors?.primary ||
      companyInfo?.colors?.primary ||
      '#00ade6',
    secondary:
      themeProvided?.secondary ||
      officeInfo?.colors?.secondary ||
      companyInfo?.colors?.secondary ||
      '#041725',
    font_family:
      themeProvided?.fontFamily ||
      officeInfo?.font_family ||
      companyInfo?.colors?.secondary,
  }

  return (
    <ThemeProvider companytheme={themeColors}>
      <BrowserRouter>
        <I18nProvider appPages={PAGES}>
          <Layout>
            <Routes />
          </Layout>
        </I18nProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default AppThemeProvider
