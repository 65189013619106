import { styled, css } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'

export const MenuRoot = styled(AppBar)`
  display: flex;
  align-items: center;
  height: 50px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.background.paper};
  gap: 48px;
  padding: 0 1.25rem;
  box-shadow: 0 1px 4px 0 ${({ theme }) => theme.palette.text.secondary};
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      padding: 0 2rem;
    }
  `}

  .Brand {
    a {
      display: flex;
    }
  }
`
