import { groupBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'

const estimateSelector = (state) => state.estimateSummary.estimate

const estimatedEditedSelector = (state) => state.estimateSummary.estimatedEdited

const estimateLoadingSelector = (state) => state.estimateSummary.loading

const estimateErrorSelector = (state) => state.estimateSummary.error

const estimateLandsSelector = (state) => state.estimateSummary?.estimate?.lands

const poisSelector = (state) => state.estimateSummary?.estimate?.pois

const poiCategoriesSelector = (state) =>
  state.estimateSummary.estimate.poiCategories

const groupedPoisSelector = createSelector(poisSelector, (pois) => {
  return Object.values(groupBy('category', pois))
})

const companySelector = (state) => state.estimateSummary.company

const userSelector = (state) => state.estimateSummary.user

const estimateSelectedDwellingsSelector = createSelector(
  estimateSelector,
  (estimate) => {
    return estimate?.dwellingSelected
  },
)

const estimateListingStatsSelector = createSelector(
  estimateSelector,
  (estimate) => {
    return estimate?.mutateAggsPurchase || []
  },
)

const annoncementSelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.annoncement || []
})

const buySelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.buy || []
})

const themeSelector = (state) => state.estimateSummary.theme

const comparablesIdsSelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.comparablesIds
})

const transactionsIdsSelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.transactionsIds
})

const comparablesSelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.comparables || []
})

const transactionsSelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.transactions || []
})

const faosSelector = createSelector(estimateSelector, (estimate) => {
  return estimate?.faos || []
})

const officeSelector = (state) => state.estimateSummary.office

const estimateDefaultValue = (state) => state.estimateSummary.defaultValue

export {
  estimateSelector,
  estimateLandsSelector,
  poisSelector,
  poiCategoriesSelector,
  groupedPoisSelector,
  estimateLoadingSelector,
  estimateErrorSelector,
  companySelector,
  estimatedEditedSelector,
  estimateSelectedDwellingsSelector,
  userSelector,
  estimateListingStatsSelector,
  annoncementSelector,
  buySelector,
  themeSelector,
  comparablesSelector,
  transactionsSelector,
  faosSelector,
  comparablesIdsSelector,
  transactionsIdsSelector,
  officeSelector,
  estimateDefaultValue,
}
