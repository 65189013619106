import { lazy } from 'react'

// ESTIMATE
const Estimate = lazy(() => import('../pages/Estimate'))

// Error
const Error = lazy(() => import('../pages/Error'))

/**
 * navigations
 */
const navigations = [
  { path: '/', private: true, estimate: true, page: <Estimate /> },
  { path: '/:lang', private: true, estimate: true, page: <Estimate /> },
  { path: '/link-expired', private: true, estimate: true, page: <Error /> },
  { path: '*', page: <Error /> },
]

export { navigations }
