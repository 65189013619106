import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  readableFinancialInteger,
  useI18n,
  toReadableNumber,
  format,
} from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'

import { StyledDiv, StyledValue, StyledTooltip } from './Description.style'

const DescriptionHeader = ({ data }: any) => {
  const { t } = useI18n()

  return (
    <>
      <StyledDiv>
        {(data.camac_code_number ||
          data.fao_code_number ||
          data.official_id) && (
          <Box display="flex">
            <span>N°</span>
            {data.camac_code_number && (
              <StyledValue>
                {data.camac_code_number !== 'null' && (
                  <a
                    href={`https://www.actis.vd.ch/rest/exp/idqry/9008/param/${data.camac_code_number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {` ${data.camac_code_number}`}
                  </a>
                )}
              </StyledValue>
            )}
            {data.official_id && <StyledValue>{data.official_id}</StyledValue>}
            {data.fao_code_number && (
              <StyledValue>{data.fao_code_number}</StyledValue>
            )}
          </Box>
        )}
        {data?.title && (
          <StyledValue style={{ marginLeft: 0 }}>{data.title}</StyledValue>
        )}
        {data?.land_id && (
          <StyledTooltip
            id="tooltip-view-icon"
            title={t('land.history.titleTab')}
          >
            <a
              aria-label="Open"
              href={`/land-detail/plot?landId=${data?.land_id}&landUsePlanStatus=now`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon />
            </a>
          </StyledTooltip>
        )}
      </StyledDiv>
      {(data.inquiry_start_date || data.date) && (
        <StyledDiv>
          <span>{data?.date ? 'Date' : 'Public consultation date'}</span>
          <StyledValue>
            {format(
              data.inquiry_start_date || data.date || data.listing_timestamp,
              'P',
            )}
          </StyledValue>
        </StyledDiv>
      )}
      {data?.ownership_type && (
        <StyledDiv>
          <span>{t('land.history.ownershipType')}</span>
          <StyledValue>{data?.ownership_type}</StyledValue>
        </StyledDiv>
      )}
      {data?.type && (
        <StyledDiv>
          <span>{t('land.history.listingType')}</span>
          <StyledValue>
            {data?.type === 'rent'
              ? t('land.history.rent')
              : t('land.history.purchase')}
          </StyledValue>
        </StyledDiv>
      )}
      {data?.reason && (
        <StyledDiv>
          <span>{t('land.history.reason')}</span>
          <StyledValue>{data?.reason}</StyledValue>
        </StyledDiv>
      )}
      {data?.price && (
        <StyledDiv>
          <span>{t('land.history.price')}</span>
          <StyledValue>
            {data.price && data.price !== null
              ? readableFinancialInteger(data.price)
              : 'NA'}{' '}
            CHF
          </StyledValue>
        </StyledDiv>
      )}
      {data?.price_per_square_meter && (
        <StyledDiv>
          <span>{t('land.history.lastKnown')}</span>
          <StyledValue>
            {readableFinancialInteger(
              data?.price_per_square_meter.toFixed(2).toString(),
            )}{' '}
            CHF
          </StyledValue>
        </StyledDiv>
      )}
      {data?.type_name && (
        <StyledDiv>
          <span>{t('land.history.type')}</span>
          <StyledValue>{`${data?.type_name} (${data?.type_code})`}</StyledValue>
        </StyledDiv>
      )}
      {data?.result_name && (
        <StyledDiv>
          <span>{t('land.history.lastKnown')}</span>
          <StyledValue>{data?.result_name}</StyledValue>
        </StyledDiv>
      )}
      {data?.area && (
        <StyledDiv>
          <span>{t('land.devScore.area')}</span>
          <StyledValue>
            {toReadableNumber(data?.area)}m<sup>2</sup>
          </StyledValue>
        </StyledDiv>
      )}
      {data?.rooms_nb && (
        <StyledDiv>
          <span>{t('land.history.rooms_nb')}</span>
          <StyledValue>{data?.rooms_nb}</StyledValue>
        </StyledDiv>
      )}
    </>
  )
}

export default DescriptionHeader
