import { memo } from 'react'
import { Outlet } from 'react-router-dom'

export type RouteGuardProps = {
  /**
   * Indicates whether or not this route requires admin role
   */
  admin?: boolean
  /**
   * Indicates whether or not this route requires authenticated user
   */
  auth?: boolean
  /**
   * Indicates whether or not this route requires landdev role
   */
  landdev?: boolean

  /**
   * Indicates whether or not this route requires estimate role
   */
  estimate?: boolean
}

/**
 * App route guard
 */
const RouteGuard = () => {
  return <Outlet />
}

export default memo(RouteGuard)
